<template>
  <div class="login_main">
    <div class="login_bg"></div>
    <el-form class="login_form" :model="form" ref="loginForm" :rules="rules">
      <h3>共享卡管理平台</h3>
      <el-form-item prop="username">
        <el-input
          v-model.trim="form.username"
          size="medium"
          maxlength="11"
          prefix-icon="el-icon-user"
          placeholder="请输入用户名"
          clearable
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          size="medium"
          :maxlength="20"
          placeholder="请输入密码"
          type="password"
          v-model="form.password"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          @click="handleLogin"
          size="medium"
          style="width: 100%"
          type="primary"
          :loading="loading"
        >
          {{ loading ? '登录中...' : '登录' }}
        </el-button>
      </el-form-item>
    </el-form>
    <div class="copyright">
      © Copyright 2019-2021. 上海闪愿网络技术有限公司 — All Rights Reservered
      <a href="https://beian.miit.gov.cn/" target="_blank">
        沪ICP备20022699号-1
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      disabled: true,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名' }],
        password: [{ required: true, message: '请输入密码' }],
      },
    };
  },
  watch: {
    'form.username': function() {
      this.$refs['loginForm'].validateField('username', errMsg => {
        this.disabled = errMsg !== '';
      });
    },
  },
  methods: {
    ...mapActions(['login']),
    handleLogin() {
      const that = this;
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          that.loading = true;
          that
            .login(that.form)
            .then(() => {
              that.$router.push({ path: '/' }, () => {
                console.log('登录成功', localStorage.getItem('ACCESS_TOKEN'));
              });
            })
            .finally(() => {
              that.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_main {
  height: 100%;
  .copyright {
    color: #666;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 60px;
    text-align: center;
    a {
      color: #666;
      cursor: pointer;
      &:hover {
        color: #03a9f4;
      }
    }
  }
  .login_bg {
    height: 35%;
    background: #03a9f4;
  }
  .login_form {
    max-width: 460px;
    margin: 0 auto;
    padding: 30px 60px;
    box-sizing: border-box;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    position: relative;
    top: -100px;
    background: #fff;
    border-radius: 4px;
    h3 {
      margin-bottom: 40px;
    }
  }
}
</style>
